import {
  DataGridProProps,
  useGridApiContext,
  GridRenderCellParams,
  GridGroupNode,
  useGridRootProps,
  getDataGridUtilityClass,
} from '@mui/x-data-grid-pro';
import { unstable_composeClasses as composeClasses, IconButtonProps } from '@mui/material';
import {
  AccountCircle,
  AssignmentOutlined,
  ContentPasteOutlined,
  ContentPasteGo,
  ContentPasteOff,
  PersonAddAltOutlined,
  PersonRemoveOutlined,
} from '@mui/icons-material';

import { useDeleteRelationship } from 'src/apis/resourcePlannerAPI';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Typography,
} from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';
import { getUserAvatar } from 'src/utils/getUserAvatar';
import { AssignActionProps, AssignmentSelectorFittedProps } from './types';
import { calculateMaxGroupingColumnWidth } from '../../helper/groupingColumnAction';

import { TaskSelector } from './components/TaskSelector';
import { ResourceSelector } from './components/ResourceSelector';
import { AssignAction } from './components/AssignAction';

import styles from './NameTreeColumnRenderer.module.scss';

const useUtilityClasses = (ownerState: { classes: DataGridProProps['classes'] }) => {
  const { classes } = ownerState;

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  };

  return composeClasses(slots, getDataGridUtilityClass, classes);
};

interface GroupingCellWithLazyLoadingProps
  extends GridRenderCellParams<any, any, any, GridGroupNode> {
  hideDescendantCount?: boolean;
}

export const NameTreeColumnRenderer = ({ id, rowNode, row }: GroupingCellWithLazyLoadingProps) => {
  const { t } = useTranslation('resourcePlanner');

  const { mutate: deleteWorkItemRelationship } = useDeleteRelationship();

  const {
    childrenFetched,
    hierarchyType,
    canAssign,
    canDelete,
    workItemId,
    resourceId,
    workItemSourceReferenceId,
    resourceSourceReferenceId,
    profileImage: profileImageLink = resourceSourceReferenceId
      ? getUserAvatar(resourceSourceReferenceId)
      : undefined,
    workItemType,
    resourceType,
    name,
    canExpand,
    type,
    role,
  } = row;

  const rootProps = useGridRootProps();

  const apiRefCellParentCell = useGridApiContext();
  const classes = useUtilityClasses({ classes: rootProps.classes });

  const hierarchyIcon = (rowType: string) => {
    if (rowType === 'Employee') {
      return profileImageLink ? (
        <Avatar src={profileImageLink} sx={{ width: 20, height: 20 }} />
      ) : (
        <AccountCircle fontSize="small" />
      );
    }
    if (rowType === 'Project') {
      return <AssignmentOutlined fontSize="small" />;
    }
    if (rowType === 'Task') {
      return <ContentPasteOutlined fontSize="small" />;
    }
    return null;
  };

  const isRowLoading = rowNode.childrenExpanded ? !childrenFetched : false;
  const IconCell = rowNode.childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  let actionProps: AssignmentSelectorFittedProps | undefined;
  let actionDeleteProps: AssignActionProps | undefined;

  const isWorkItem = hierarchyType === 'workitem';

  if (canAssign) {
    actionProps = {
      iconName: isWorkItem ? (
        <PersonAddAltOutlined fontSize="small" />
      ) : (
        <ContentPasteGo fontSize="small" />
      ),
      tooltipText: isWorkItem ? t('SelectAPersonChildren') : t('SelectATaskChildren'),
      heading: isWorkItem ? t('SelectAPersonChildren') : t('SelectATaskChildren'),
      closeText: isWorkItem ? t('SelectAPersonCloseText') : t('SelectAPersonCloseText'),
      itemId: (isWorkItem ? workItemId : resourceId) || '',
      itemSourceReferenceId:
        (isWorkItem ? workItemSourceReferenceId : resourceSourceReferenceId) || '',
      itemType: (isWorkItem ? workItemType : resourceType) || '',
      itemTitle: name,
      'data-automation-id': isWorkItem
        ? 'AssignResourceToTaskModalButton'
        : 'AssignTaskToResourceModalButton',
    };
  }

  if (canDelete) {
    actionDeleteProps = {
      iconName: isWorkItem ? (
        <ContentPasteOff fontSize="small" />
      ) : (
        <PersonRemoveOutlined fontSize="small" />
      ),
      tooltipText: isWorkItem ? t('RemoveTask') : t('RemoveAllocation'),
      heading: isWorkItem ? t('RemoveTask') : t('RemoveAllocation'),
      closeText: isWorkItem ? t('SelectAPersonCloseText') : t('SelectAPersonCloseText'),
      children: isWorkItem ? t('ConfirmRemoveTask') : t('ConfirmRemoveAllocation'),
      'data-automation-id': isWorkItem
        ? 'DeleteResourceToTaskModalButton'
        : 'DeleteTaskToResourceModalButton',
      onConfirm: () =>
        deleteWorkItemRelationship({
          workItemId,
          workItemSourceReferenceId,
          workItemType,
          resourceId,
          resourceSourceReferenceId,
          resourceType,
        }),
    };
  }
  const handleClick: IconButtonProps['onClick'] = () => {
    apiRefCellParentCell.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    setTimeout(() => {
      apiRefCellParentCell.current.setColumnWidth(
        '__tree_data_group__',
        calculateMaxGroupingColumnWidth(),
      );
    }, 100);
  };

  return (
    <Box className={classes.root} sx={{ pl: rowNode.depth * 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
        <span>
          {canExpand &&
            (isRowLoading ? (
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <CircularProgress size="1rem" color="inherit" />
              </Box>
            ) : (
              <IconButton
                id="toggleRowButton"
                size="small"
                onClick={handleClick}
                tabIndex={-1}
                title={
                  rowNode.childrenExpanded ? t('RowButtonLabelCollapse') : t('RowButtonLabelExpand')
                }
                aria-label={
                  rowNode.childrenExpanded
                    ? apiRefCellParentCell.current.getLocaleText('treeDataCollapse')
                    : apiRefCellParentCell.current.getLocaleText('treeDataExpand')
                }
              >
                <IconCell fontSize="small" />
              </IconButton>
            ))}
          <div className={styles.spacer} />
        </span>
        <div className={styles.icon}>{hierarchyIcon(type)}</div>
        <span className={styles.name}>
          {type === 'Project' && (
            <Link
              href={`/ProjectManagement/Plan/Task/${workItemSourceReferenceId}`}
              title={t('ProjectLinkTooltip')}
              color="inherit"
              underline="hover"
            >
              {rowNode.groupingKey}
            </Link>
          )}
          {type === 'Task' && <span>{rowNode.groupingKey}</span>}
          {type === 'Employee' && (
            <>
              <Typography>{rowNode.groupingKey}</Typography>
              {role && <Typography fontSize="12px">{role}</Typography>}
            </>
          )}
        </span>
      </Box>
      <Stack direction="row">
        {actionProps &&
          (isWorkItem ? <ResourceSelector {...actionProps} /> : <TaskSelector {...actionProps} />)}
        {actionDeleteProps && <AssignAction {...actionDeleteProps} />}
      </Stack>
    </Box>
  );
};
