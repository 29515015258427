import { useState } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/components/mui-components';
import { useTranslation } from 'react-i18next';
import { AssignActionProps } from '../../types';

export const AssignAction = ({
  iconName,
  tooltipText,
  heading,
  isOpen,
  setIsOpen,
  onDismiss,
  children,
  footerComponent,
  onConfirm,
}: AssignActionProps) => {
  const { t } = useTranslation('resourcePlanner');
  const [isOpenLocal, setIsOpenLocal] = useState(false);
  const open = () => (setIsOpen ? setIsOpen(true) : setIsOpenLocal(true));
  const close = () => (setIsOpen ? setIsOpen(false) : setIsOpenLocal(false));
  const onConfirmWithClose = () => {
    if (onConfirm) onConfirm();
    close();
  };
  const localOnDismiss = onDismiss || close;

  return (
    <>
      <IconButton onClick={open} tabIndex={-1} title={tooltipText}>
        {iconName}
      </IconButton>
      <Dialog open={isOpen || isOpenLocal} onClose={localOnDismiss} fullWidth maxWidth="xs">
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>{children}</DialogContent>

        {footerComponent && <DialogActions>{footerComponent}</DialogActions>}

        {onConfirm && (
          <DialogActions>
            <Button onClick={close}>{t('Cancel')}</Button>
            <Button onClick={onConfirmWithClose}>{t('Ok').toUpperCase()}</Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
