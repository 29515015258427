import { memo } from 'react';
import { CustomizedCell } from '../CustomizedCell';
import { IPeriodColumn, RPRow } from '../../types/resourcePlanner';

type IMemoizedRenderBodyCellPeriodColumn = {
  row: RPRow;
  column: IPeriodColumn;
  value: string;
  displayValue: string;
  isEditable: boolean;
  onCellValueChange: (row: RPRow, column: IPeriodColumn, value: string) => void;
  unitType: string;
  reportingType: string;
};

export const MemoizedRenderBodyCellPeriodColumnMui = memo(
  ({
    row,
    column,
    value,
    displayValue,
    isEditable,
    onCellValueChange,
    unitType,
    reportingType,
  }: IMemoizedRenderBodyCellPeriodColumn) => (
    <CustomizedCell
      row={row}
      column={column}
      displayValue={displayValue}
      value={value}
      isEditable={isEditable}
      onCellValueChange={onCellValueChange}
      unitType={unitType}
      reportingType={reportingType}
    />
  ),
  (prevProp, nextProp) => {
    const isSameRow = prevProp.row === nextProp.row;

    const isSameColumn = prevProp.column === nextProp.column;

    const isSameValue = prevProp.value === nextProp.value;

    const isSameDisplayValue = prevProp.displayValue === nextProp.displayValue;

    const isSameCellEditable = prevProp.isEditable === nextProp.isEditable;

    const isSameUnitType = prevProp.unitType === nextProp.unitType;

    const isSameReportingType = prevProp.reportingType === nextProp.reportingType;

    return (
      isSameRow &&
      isSameColumn &&
      isSameValue &&
      isSameDisplayValue &&
      isSameCellEditable &&
      isSameUnitType &&
      isSameReportingType
    );
  },
);
