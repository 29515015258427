import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, TextField, Tooltip } from 'src/components/mui-components';
import DoneIcon from '@mui/icons-material/Done';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { convertNumber } from 'src/utils/number';
import { stringToPascal } from 'src/utils/string';
import { formatTime, validateTimeInput } from 'src/utils/time';
import { IPeriodColumn, RPRow } from '../../types/resourcePlanner';
import styles from './CustomizedCell.module.scss';

type CustomizedCellProps = {
  row: RPRow;
  column: IPeriodColumn;
  value: string;
  displayValue: string;
  isEditable: boolean;
  onCellValueChange: (row: RPRow, column: IPeriodColumn, value: string) => void;
  unitType: string;
  reportingType: string;
};

export const CustomizedCell = ({
  row,
  column,
  value,
  displayValue,
  isEditable,
  onCellValueChange,
  unitType,
  reportingType,
}: CustomizedCellProps) => {
  const { t } = useTranslation('resourcePlanner');
  const siteLocale = useGetLocale();
  const actualValue = displayValue || '0';
  const fullValue = formatTime(value, siteLocale, 'DECIMAL', 5);
  const actualValueInt = parseFloat(actualValue);
  const time = formatTime(actualValue, siteLocale);
  const [editValue, setEditValue] = useState<string>(time);
  const [error, setError] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const emptyString: string = '';

  useEffect(() => {
    if (actualValueInt >= 0) {
      setEditValue(
        actualValueInt === 0
          ? emptyString
          : actualValueInt.toLocaleString(siteLocale, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
      );
    }
  }, [actualValueInt, siteLocale]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
    setIsUpdated(true);
  }, []);

  const handleBlur = useCallback(() => {
    const currentValue = editValue === emptyString ? '0' : editValue;
    const { status, value: outputValue } = validateTimeInput(
      currentValue,
      unitType,
      t('UseDecimalFormatValidationFeedback'),
    );
    if (isUpdated) {
      if (status === 'error') {
        setError(outputValue);
        return;
      }

      const currentValueInt = convertNumber(currentValue, siteLocale);
      const formattedCurrentValue = currentValueInt.toLocaleString(siteLocale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setIsSaved(true);
      setIsUpdated(false);
      setEditValue(currentValueInt === 0 ? emptyString : formattedCurrentValue);

      if (!Number.isNaN(currentValueInt) && currentValueInt !== actualValueInt) {
        setError('');
        if (onCellValueChange) {
          onCellValueChange(row, column, currentValueInt.toString());
        }
      }
    }
  }, [
    actualValueInt,
    column,
    editValue,
    isUpdated,
    onCellValueChange,
    row,
    siteLocale,
    t,
    unitType,
  ]);

  const onFocus = () => {
    setIsSaved(false);
  };

  const isUnitTypePercentage = unitType && unitType === 'percentages';
  if (!isEditable) {
    if (reportingType === 'utilization') {
      if (isUnitTypePercentage) {
        return (
          <Tooltip title={fullValue}>
            <>
              <span className={styles.inputCellWithoutInput}>
                {actualValue === undefined || actualValue === '0'
                  ? emptyString
                  : `${formatTime(actualValue, siteLocale)}`}
              </span>
              <span>{actualValue === undefined || actualValue === '0' ? emptyString : '%'}</span>
            </>
          </Tooltip>
        );
      }
      if (!isUnitTypePercentage && actualValue === '0') {
        return <span className={styles.inputCellWithoutInput}>{emptyString}</span>;
      }
    }

    if (reportingType === 'availability') {
      if (isUnitTypePercentage) {
        return (
          <>
            <span className={styles.inputCellWithoutInput}>
              {actualValue === undefined ? emptyString : `${formatTime(actualValue, siteLocale)}`}
            </span>
            <span>{actualValue === undefined ? emptyString : '%'}</span>
          </>
        );
      }

      if (!isUnitTypePercentage && actualValue === '0') {
        return (
          <span className={styles.inputCellWithoutInput}>{`${formatTime('0', siteLocale)}`}</span>
        );
      }
    }
    return (
      <span className={styles.inputCellWithoutInput} title={fullValue}>
        {actualValue === undefined ? emptyString : time}
      </span>
    );
  }

  return (
    <div>
      {isEditable ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={0.5}
          position="relative"
        >
          <TextField
            hiddenLabel
            ariaLabel={`tableRow${row.id} tableColumn${stringToPascal(column?.identifier)}`}
            size="small"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={onFocus}
            value={editValue}
            errorMessage={error}
            usePopOutValidation
            status="error"
            className={classNames(styles.customizedCell, {
              [styles.saved]: isSaved,
            })}
            data-automation-id="ExchangeRateTableCellEditInput"
          />
          {isSaved && (
            <div className={styles.inputSavedIcon}>
              <DoneIcon fontSize="small" />
            </div>
          )}
          {isUnitTypePercentage && <span>%</span>}
        </Stack>
      ) : null}
    </div>
  );
};
