import { GridApi, GridRowModel, GridRowId } from '@mui/x-data-grid-pro';
import { RPinvalidateOldQueryOnViewOptionChange } from '../localStorageKeys';

// Calculate the max width of the Grouping column, and manually set the width
// Pending official fix https://github.com/mui/mui-x/issues/10848
export const calculateMaxGroupingColumnWidth = () => {
  const groupingCells = document.querySelectorAll(
    '.MuiDataGrid-pinnedColumns .MuiDataGrid-row .MuiDataGrid-treeDataGroupingCell',
  );
  let maximumWidth = 0;
  groupingCells.forEach((cell) => {
    const htmlCell = cell as HTMLElement;
    const cellStyle = window.getComputedStyle(htmlCell);
    const cellPaddingLeft = parseFloat(cellStyle.paddingLeft);
    const firstContentCell = htmlCell.querySelector('.MuiBox-root:nth-child(1)') as HTMLElement;
    const secondContentCell = htmlCell.querySelector('.MuiBox-root:nth-child(2)') as HTMLElement;
    const secondContentCellWidth = secondContentCell ? secondContentCell.clientWidth + 60 : 60;
    const firstContentCellStyle = firstContentCell
      ? window.getComputedStyle(firstContentCell)
      : null;
    const firstContentCellPaddingLeft = firstContentCellStyle
      ? parseFloat(firstContentCellStyle.paddingLeft)
      : 0;
    let firstContentCellChildrenWidth = 0;
    if (firstContentCell) {
      const { children: contentCell } = firstContentCell;
      for (let i = 0; i < contentCell.length; i += 1) {
        const child = contentCell[i] as HTMLElement;
        firstContentCellChildrenWidth += child.clientWidth;
      }
    }
    const totalWidth =
      cellPaddingLeft +
      firstContentCellPaddingLeft +
      firstContentCellChildrenWidth +
      secondContentCellWidth;
    if (totalWidth > maximumWidth) {
      maximumWidth = totalWidth;
    }
  });
  return maximumWidth;
};

// To programmatically expand 1 level of the tree using MUI API setRowChildrenExpansion method
export const expandRows = (apiRef: React.MutableRefObject<GridApi>) => {
  const rowIds = apiRef.current.getAllRowIds();

  // Get all row nodes (Row node details)
  const allRowNode = rowIds.map((id: GridRowId) => apiRef.current.getRowNode(id));

  if (allRowNode.length > 0) {
    // Find the maximum depth of expanded rows
    const maxDepth = allRowNode.reduce((max: number, row: GridRowModel | null) => {
      if (row?.children && row.children.length > 0 && row.childrenExpanded) {
        return Math.max(max, row.depth);
      }
      return max;
    }, -1);

    // Find the minimum depth of collapsed rows
    const minDepth = allRowNode.reduce((min: number, row: GridRowModel | null) => {
      if (row?.children && row.children.length > 0 && !row.childrenExpanded) {
        return Math.min(min, row.depth);
      }
      return min;
    }, maxDepth);

    // Filter rows that are not leaf nodes, have depth equal to minDepth and are not expanded
    let allShouldExpandRowId = allRowNode.filter(
      (row: GridRowModel | null) =>
        row?.type !== 'leaf' && row?.depth === minDepth && !row?.childrenExpanded,
    );

    // Check if all children are expanded
    const isAllChildExpanded = allRowNode.filter(
      (row: GridRowModel | null) => row?.type !== 'leaf' && !row?.childrenExpanded,
    );

    // If there are no rows to expand and there are children that are not expanded,
    // filter rows that are not leaf nodes, have depth equal to minDepth + 1 and are not expanded
    if (allShouldExpandRowId?.length <= 0 && isAllChildExpanded?.length > 0) {
      allShouldExpandRowId = allRowNode.filter(
        (row: GridRowModel | null) =>
          row?.type !== 'leaf' && row?.depth === minDepth + 1 && !row?.childrenExpanded,
      );
    }

    // Expand all rows in allShouldExpandRowId
    allShouldExpandRowId.forEach((row: GridRowModel | null) => {
      apiRef.current.setRowChildrenExpansion(row?.id, true);
    });

    localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'true');

    setTimeout(() => {
      apiRef.current.setColumnWidth('__tree_data_group__', calculateMaxGroupingColumnWidth());
    }, 100);
  }
};

// To programmatically collapse 1 level of the tree using MUI API setRowChildrenExpansion method
export const collapseRow = (apiRef: React.MutableRefObject<GridApi>) => {
  const rowIds = apiRef.current.getAllRowIds();

  const allRowNode = rowIds.map((id: GridRowId) => apiRef.current.getRowNode(id));

  if (allRowNode.length > 0) {
    // Find the maximum depth among all row nodes
    const maxDepth = allRowNode.reduce((max: number, row: GridRowModel | null) => {
      if (row?.children && row.children.length > 0 && row.childrenExpanded) {
        return Math.max(max, row.depth);
      }
      return max;
    }, -1);

    // Find all row nodes that should be collapsed
    const allShouldCollapseRowId = allRowNode.filter(
      (row: GridRowModel | null) => row?.type !== 'leaf' && row?.depth === maxDepth,
    );

    // Collapse each row node that should be collapsed
    allShouldCollapseRowId.forEach((row: GridRowModel | null) => {
      apiRef.current.setRowChildrenExpansion(row?.id, false);
    });

    localStorage.setItem(RPinvalidateOldQueryOnViewOptionChange, 'true');

    setTimeout(() => {
      apiRef.current.setColumnWidth('__tree_data_group__', calculateMaxGroupingColumnWidth());
    }, 100);
  }
};
